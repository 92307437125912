<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">{{ $t("AUTH.TWO_FACTOR.TITLE") }}</h3>
        <p class="text-muted font-weight-semi-bold">
          {{ $t("AUTH.TWO_FACTOR.DESCRIPTION") }}
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group id="code-input-group" label="" label-for="code-input">
          <b-form-input
            id="code-input"
            name="code"
            v-model="$v.form.code.$model"
            :state="validateState('code')"
            aria-describedby="code-input-live-feedback"
            v-bind:placeholder="$t('AUTH.TWO_FACTOR.CODE')"
          ></b-form-input>

          <b-form-invalid-feedback id="code-input-live-feedback">
            {{ $t("VALIDATION.LENGTH", { name: "Code", length: "6" }) }}
          </b-form-invalid-feedback>
          <b-form-valid-feedback>
            {{ $t("VALIDATION.VALID") }}
          </b-form-valid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <router-link to="/login">
            <a class="kt-link kt-login__link-forgot">
              {{ $t("AUTH.LOGIN.TITLE") }}
            </a>
          </router-link>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            {{ $t("BUTTON.READY") }}
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { TWOFACTOR } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, maxLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "twoFactor",
  data() {
    return {
      form: {
        code: ""
      }
    };
  },
  mounted() {
    // check if current user is authenticated
    if (this.isAuthenticated) {
      this.$router.push({ name: "dashboard" });
    }
  },
  validations: {
    form: {
      code: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        code: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const code = this.$v.form.code.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(TWOFACTOR, { code })
        // go to which page after successfully login
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
